export default async function ({ app }) {
    if (!app.$auth.loggedIn) {
        return
    } else {
        const auth = app.$auth;
        const authStrategy = auth.strategy.name;
        if (authStrategy === 'facebook') {
            let data2 = {
                fb_token: auth.user.id,
                first_name: auth.user.name
            }
            try {
                const response = await app.$axios.$post("/api/oauth", data2);
                await auth.setStrategy('local');
                await auth.strategy.token.set("Bearer " + response.user.token);
                await auth.fetchUser();

            } catch (e) {
                throw new Error(e)
            }
        } else if (authStrategy === 'google') {
            let dataGoogle = {
                google_token: auth.user.sub,
                first_name: auth.user.given_name,
                last_name:auth.user.family_name
            }
            try {
                const response = await app.$axios.$post("/api/oauth", dataGoogle);
                await auth.setStrategy('local');
                await auth.strategy.token.set("Bearer " + response.user.token);
                await auth.fetchUser();

            } catch (e) {
               throw new Error(e)
            }
        }

    }
}