import { Logger } from '~/helpers/logger';
import { Cart } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';

export const removeCouponCommand = {
  execute: async (context: VsfContext, { currentCart, customQuery = { removeCouponFromCart: 'removeCouponFromCart' } }) => {

    const { data, errors } = await context.$magento.api.removeCouponFromCart({
      cart_id: currentCart.id,
    }, customQuery);

    return {
      updatedCart: data.removeCouponFromCart?.cart as unknown as Cart,
      updatedCoupon: { code: '' },
      errors,
    };
  },
};
