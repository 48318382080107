import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { findItemOnWishlist } from '~/modules/wishlist/helpers/findItemOnWishlist';
import { Logger } from '~/helpers/logger';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import type { Wishlist } from '~/modules/GraphQL/types';
import type {
  UseWishlistAddItemParams,
  UseWishlistErrors,
  UseWishlistInterface,
  UseWishlistIsInWishlistParams,
  UseWishlistLoadParams,
  UseWishlistRemoveItemParams,
  UseWishlistAfterAddingWishlistItemToCartParams,
} from '~/modules/wishlist/composables/useWishlist/useWishlist';
import { useUiNotification } from '~/composables';

/**
 * The `useWishlist()` composable allows loading and manipulating wishlist of the current user.
 *
 * See the {@link UseWishlistInterface} page for more information.
 */
export function useWishlist(): UseWishlistInterface {
  const wishlistStore = useWishlistStore();
  const { app } = useContext();
  const { send: sendNotification } = useUiNotification();
  const loading = ref(false);
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  const calculateWishlistTotal = (wishlists) => wishlists.reduce((prev, next) => (prev?.items_count ?? 0) + (next?.items_count ?? 0), 0);
  const error = ref<UseWishlistErrors>({
    addItem: null,
    removeItem: null,
    load: null,
    clear: null,
    loadItemsCount: null,
    afterAddingWishlistItemToCart: null,
  });

  // eslint-disable-next-line consistent-return
  const load = async (params?: UseWishlistLoadParams) => {

    try {
      loading.value = true;
      const apiState = app.context.$vsf.$magento.config.state;

      if (apiState.getCustomerToken()) {
        const { data } = await app.context.$vsf.$magento.api.wishlist(params?.searchParams, params?.customQuery);

        const loadedWishlist = data?.customer?.wishlists ?? [];
        wishlistStore.wishlist = loadedWishlist[0] ?? {};
      }

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
    } finally {
      loading.value = false;
    }

    return wishlistStore.wishlist;
  };

  const isInWishlist = ({ product }: UseWishlistIsInWishlistParams) => {

    const wishlistProduct = findItemOnWishlist(wishlistStore.wishlist, product);

    return !!(wishlistProduct?.id && wishlistProduct?.quantity);
  };

  const setWishlist = (newWishlist: Wishlist) => {
    wishlistStore.wishlist = newWishlist;
  };

  const removeItem = async ({ product, customQuery }: UseWishlistRemoveItemParams) => {

    try {
      loading.value = true;

      const itemOnWishlist = findItemOnWishlist(wishlistStore.wishlist, product);
      const { data } = await app.context.$vsf.$magento.api.removeProductsFromWishlist({
        id: '0',
        items: [itemOnWishlist.id],
      }, customQuery);

      error.value.removeItem = null;
      wishlistStore.$patch((state) => {
        state.wishlist = data?.removeProductsFromWishlist?.wishlist ?? {};
      });
    } catch (err) {
      error.value.removeItem = err;
    } finally {
      loading.value = false;
    }
  };

  const loadItemsCount = async (): Promise<number | null> => {
    const apiState = app.context.$vsf.$magento.config.state;
    let itemsCount = null;

    try {
      loading.value = true;
      error.value.loadItemsCount = null;
      if (apiState.getCustomerToken()) {
        const { data } = await app.context.$vsf.$magento.api.wishlistItemsCount();

        const loadedWishlist = data?.customer?.wishlists ?? [];
        itemsCount = calculateWishlistTotal(loadedWishlist);
        wishlistStore.$patch((state) => {
          state.wishlist.items_count = itemsCount;
        });
      }
    } catch (err) {
      error.value.loadItemsCount = err;
    } finally {
      loading.value = false;
    }

    return itemsCount;
  };

  // eslint-disable-next-line consistent-return
  const addItem = async ({ product, customQuery }: UseWishlistAddItemParams) => {

    try {
      loading.value = true;

      if (!wishlistStore.wishlist) {
        await load({});
      }

      const itemOnWishlist = findItemOnWishlist(wishlistStore.wishlist, product);

      // todo: legacy code, should be double-checked and probably removed
      if (itemOnWishlist) {
        return await removeItem({
          product,
        });
      }

      // @ts-ignore
      // eslint-disable-next-line no-underscore-dangle
      switch (product.__typename) {
        case 'VirtualProduct':
        case 'DownloadableProduct':
        case 'GroupedProduct':
        case 'GiftCard':
        case 'SimpleProduct': {
          const { data } = await app.context.$vsf.$magento.api.addProductToWishList({
            id: '0',
            items: [{
              sku: product.sku,
              quantity: 1,
            }],
          }, customQuery);


          wishlistStore.$patch((state) => {
            state.wishlist = data?.addProductsToWishlist?.wishlist ?? {};
          });

          break;
        }
        case 'ConfigurableProduct': {
          const { data: configurableProductData } = await app.context.$vsf.$magento.api.addProductToWishList({
            id: '0',
            items: [{
              sku: product.configurable_product_options_selection?.variant?.sku || product.sku,
              quantity: 1,
              parent_sku: product.sku,
            }],
          }, customQuery);


          wishlistStore.$patch((state) => {
            state.wishlist = configurableProductData?.addProductsToWishlist?.wishlist ?? {};
          });

          break;
        }
        case 'BundleProduct': {
          const { data: bundleProductData } = await app.context.$vsf.$magento.api.addProductToWishList({
            id: '0',
            items: [{
              sku: product.sku,
              quantity: 1,
              entered_options: [],
            }],
          }, customQuery);


          wishlistStore.$patch((state) => {
            state.wishlist = bundleProductData?.addProductsToWishlist?.wishlist ?? {};
          });

          break;
        }
        default:
          // todo implement other options
          // @ts-ignore
          // eslint-disable-next-line no-underscore-dangle
      }
    } catch (err) {
      error.value.addItem = err;
    } finally {
      loading.value = false;
    }
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  const clear = async () => {

    try {
      loading.value = true;
      error.value.clear = null;
      wishlistStore.$patch((state) => {
        state.wishlist = {};
      });
    } catch (err) {
      error.value.clear = err;
    } finally {
      loading.value = false;
    }
  };

  const afterAddingWishlistItemToCart = (
    { product, cartError }: UseWishlistAfterAddingWishlistItemToCartParams,
  ) => {

    if (!isInWishlist({ product })) return;

    try {
      if (cartError?.message) {
        sendNotification({
          id: Symbol('product_added_to_cart_from_wishlist_error'),
          message: cartError.message,
          type: 'danger',
          icon: 'cross',
          persist: false,
          title: 'Wishlist error',
        });
      } else {
        // eslint-disable-next-line promise/catch-or-return
        removeItem({ product })
          // eslint-disable-next-line promise/always-return
          .then(() => {
            sendNotification({
              id: Symbol('product_added_to_cart_from_wishlist'),
              message: app.i18n.t(
                'You added {product} to your shopping cart.',
                { product: product.name },
              ) as string,
              type: 'success',
              icon: 'check',
              persist: false,
              title: 'Wishlist',
            });
          });
      }
    } catch (err) {
      error.value.afterAddingWishlistItemToCart = err;
    }
  };

  return {
    loadItemsCount,
    isInWishlist,
    addItem,
    load,
    removeItem,
    clear,
    setWishlist,
    afterAddingWishlistItemToCart,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useWishlist;
export * from './useWishlist';
