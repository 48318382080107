import { gql } from 'graphql-request';

/**
 * GraphQL Query that fetches products using received search term and the params
 * for filter, sort and pagination.
 */
export default gql`
  query getFacetDataTotalCount($search: String = "", $filter: ProductAttributeFilterInput, $pageSize: Int = 10, $currentPage: Int = 1, $sort: ProductAttributeSortInput) {
    products(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
  }
`;
