/* eslint-disable  */

export default {
  "FAQ": "FAQ",
  "Find_my_order": "Chercher ma commande",
  "Contact_us": "Contactez-nous",
  "Stores": "Boutiques",
  "I am a professional": "Je suis un professionnel",
  'phone_format': 'Le format du numéro de téléphone est incorrect',
  "Home": "Accueil",
  "Menu": "Menu",
  "All dress": "toutes les robes",
  "All accessories": "toutes les accessories",
  "Wishlist": "Wishlist",
  "Account": "Mon compte",
  "Cart": "Panier",
  "Help": "Aide et contact",
  "Size guide": "Guide des tailles",
  "Delivery & returns": "Livraison & retours",
  "return product": "Rendez-vous sur WhatsApp ou WeChat et contactez nos équipes pour débuter votre retour. Les retours sont à votre charge sauf en cas de produit défectueux.",
  "About us": "A propos de nous",
  "Shops & partners": "Nos boutique et nos partenaires",
  "Lookbook": 'Lookbook',
  "Events": 'Nos événements',
  "Collections": 'Nos collections',
  "Informations": 'Infomations',
  "Legal notice": "Mentions lègales",
  "Terms of Sale": "Conditions générales de vente",
  "Privacy Policy": "Politique des données confidentielles",
  "Cookies Policy": "Gestion de cookies",
  "Social": 'Nos réseaux sociaux',
  "follow us": "Suivez-nous !",
  "Quick delivery": "Livraison rapide",
  "Protected parcels": "Colis protégés",
  "Secure payment": "Paiement sécurisé",
  "Subscribe to newsletter": "S'abonner à la newsletter",
  "the news": 'Les nouveautés Promulias !',
  "your@mail-address.com": "votre@adresse-mail.com",
  "subscrire": "S'abonner",
  "the Promulias universe": 'L\'univers Promulias',
  "Read all reviews": "Consulter les avis",
  "Check Sizes": "Guide des tailles",
  "Contact us": "Contactez-nous",
  "Size": "Taille",
  "Color": "Couleur",
  "products in stock": "En stock",
  "Notify me when available": 'Me prévenir quand il sera disponible',
  "Add to cart": "Ajouter au panier",
  "This color is not available": 'Cette couleur n\'est pas disponible',
  "This size is not available": 'Cette taille n\'est pas disponible',
  "Insuficient stock": 'Le stock est insuffisant',
  "Instruction1": "Comment effectuer un retour",
  "Would love you too": 'Vous aimerez aussi...',
  "See our similar article": 'Promulias vous propose un large choix de robes aux tissus et formes variées. Chacune y trouvera une façon unique de sublimer sa silhouette pour se rendre à une soirée élégante.',
  "Discover Promulias": 'Découvrez Promulias',
  "Explore our lookbook": "Explorez notre lookbook",
  "Are you sure you would like to remove this item from the shopping cart?": 'Souhaitez-vous vraiment supprimer cet article du panier ?',
  "Order summary": "Détails de la commande",
  "Quantity": "Quantité",
  "Subtotal": "Sous-total",
  "Tax label": "Dont TVA 20%",
  "Discount": "Promotion",
  "Shipping begin": "Livraison à partir de",
  "Total": "Total",
  "Enter promo code": "Ajouter un code promo",
  "Go to checkout": "Valider mon panier",
  "continue_shopping": "Continuer vos achats",
  "Find your favorite articles": 'Retrouvez vos articles préférés',
  "Go to my Wishlist": 'Aller à ma Wishlist',
  "Your bag is empty": "Votre panier est vide",
  "it looks like you haven't added any items to the cart yet": 'Il semblerait que vous n\'ayez pas encore ajouté d\'articles au panier',
  "Continue your shopping": 'Continuer vos achats',
  "Return to cart": "Retourner au panier",
  "Connection": "Connexion",
  "Shipping": "Livraison",
  "Payment": "Paiement",
  "I want to register": "Je souhaite créer un compte",
  "I want to connect": "Je souhaite me connecter",
  "E-mail": 'Adresse e-mail',
  "Passwords": "Mot de passe",
  "First Name": "Prénom",
  "Last Name": "Nom de famille",
  "Number, label and route": "Numéro, libellé et voie",
  "Entrance, building and apartment": "Entrée, bâtiment et appartement",
  "City": "Ville",
  "State/Province": "Région",
  "Zip-code": "Code postal",
  "Country": "Pays",
  "Phone_number": "Numéro de téléphone",
  "Shipping method": "Mode de livraison",
  "Next": 'Suivant',
  "My billing and shipping address are the same": "Mon adresse de facturation est la même que l'adresse de livraison",
  "Use this adress": 'Utiliser cette addresse',
  "Pay for order": "Payer ma commande",
  "Thanks_you_for_order": "Nous vous remercions de votre commande !",
  "Order_ID": 'Numéro de commande',
  "Your_Purchase": "Votre achat",
  "Successful_placed_order": "Votre commande a été passée avec succès. Vous pouvez vérifier son état depuis votre espace client. Vous recevrez un e-mail de confirmation avec les détails de votre achat.",
  "Successfukk_placed_order1": 'En cas d\'une livraison à domicile, vous serez informé par email lors de la prise en charge de votre colis par le transporteur.',
  "Successfukk_placed_order2": 'En cas d\'un retrait en boutique, vous recevrez un email de confirmation lorsque votre commande sera prête à être retirée.',
  "See_my_order": 'Voir ma commande',
  "Question": "Des questions ?",
  "Questions content": "Vous avez une question, besoin d'un avis ou d'une recommendation, d'aide sur un produit ou votre commande, rendez-vous sur notre page de contact.",
  "question-faq": "Donec rutrum congue leo eget malesuada. Proin eget tortor risus. Curabitur aliquet quam id dui posuere blandit. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Cras ultricies ligula sed magna dictum porta.",
  "subcontent_title": "Accès des clients particuliers.",
  "professional": "Vous êtes professionnels ? ",
  "click_here": "Cliquez ici",
  "Your email": "Votre adresse e-mail",
  "Error email": "Veuillez fournir une adresse e-mail valide",
  "Password": "Votre mot de passe",
  "This field is required": "Ce champ est requis",
  "Login": "Se connecter",
  "Forgotten password?": "Mot de passe oublié ?",
  "No account": "Vous n'avez pas encore de compte ?",
  "Register today": "Inscrivez-vous maintenant !",
  "Forgot_Password": "Mot de passe oublié",
  "Forgot_password_content_modal_1": "Saisissez l'adresse e-mail que vous utilisez pour vous connecter.",
  "Forgot_password_content_modal_2": "Un e-mail vous seras envoyé pour réinitialiser votre mot de passe.",
  "Receive_email": "Recevoir l'email",
  "Register_New_Password": "Renseignez votre nouveau mot de passe.",
  "Required_1": "Il doit contenir au minimum 8 caractères, 1 majuscule, 1 minuscule et 1 chiffre.",
  "Required_2": "",
  "Repeat Password": 'Confirmer votre mot de passe',
  "Save_Password": "Réinitialiser votre mot de passe",
  "Civility": "Civilité",
  "Birthday": "Date de naissance",
  "Confirm_Password": "Confirmer le mot de passe",
  "Commercial_infos": "Je souhaite recevoir les informations et les différentes offres de Promulias",
  "CGU": "J\'accepte les ",
  "CGU-CTA": "Conditions Générales d'Utilisation",
  "Prom_account": "du compte Promulias",
  "To sign up": "S'inscrire",
  "My profile": "Modifier mon profil",
  "My addresses": "Mes adresses",
  "Feel free to edit": "Modifier mes informations personnelles",
  "My newsletter": "Ma newsletter",
  "My referrals" : "Mes parrainages",
  "Order history": "Historique des commandes",
  "Order details": "Détails des commandes",
  "Log out": "Se déconnecter",
  "Update password": "Modifier le mot de passe",
  "To Register": "Enregistrer",
  "Use your personal data": "Chez Promulias, nous attachons une grande importance aux questions de confidentialité et nous nous engageons à protéger les données personnelles de nos utilisateurs. En savoir plus sur la façon dont nous prenons soin et utilisons vos données personnelles dans la",
  "Current Password": "Mot de passe actuel",
  "New Password": "Nouveau mot de passe",
  "Default Billing Address": "Adresse de facturation",
  "Billing Address": "Adresse de facturation",
  "Default Shipping Address": "Adresse de livraison",
  "Change": "Changer",
  "Delete": "Supprimer",
  "Add new address": "Ajouter une nouvelle adresse",
  "Add the address": "Ajouter une adresse",
  "Update the address":"Modifier mon adresse",
  "Set as default shipping": "Définir comme adresse de livraison par défaut",
  "Set as default billing": "Définir comme adresse de facturation par défaut",
  "Manage my newsletter": 'Gérer ma newsletter',
  "Sign Up for Newsletter": "Souhaitez-vous découvrir nos collections, recevoir des promotions exclusives et participer à nos événements ? Pour vous désinscrire, un lien en bas de chaque mail vous permettra de vous désabonner.",
  "My orders": "Mes commandes",
  "Use this address": "Utiliser cette adresse",
  "Orders": "Commande",
  "My returns": "Mes retours",
  "You currently have no orders": "Vous n'avez actuellement aucun historique de commande",
  "Payment_date": 'Date de paiment',
  "Amount": 'Montant',
  "Status": 'Statut',
  "Product": "Produit",
  "Price": "Prix",
  "Order_Number": "Numéro de commande",
  "Date": 'Date d\'achat',
  "Payment method": "Mode de paiement",
  "Ask_for_billing": 'Demande de facturation',
  "Returns": "Mes retours",
  "Our delivery methods": "Nos modes de livraison :",
    "To the address of your choice": "À l’adresse de votre choix :",
    "description list 1": "Livraison sous 24 à 48h en France Métropolitaine.",
    "description list 2": "Dans la limite de 70 Kg par commande.",
    "By withdrawal in our Promulias stores": "En retrait dans nos boutiques Promulias :",
    "description list 3": "55 rue Popincourt, 75011 Paris",
    "description list 4": "111 rue d'Aboukir, 75002 Paris",
    "How to return an item": "Comment retourner un article ?",
    "description returns 1": "Quelle que soit la raison pour laquelle vous n'êtes pas satisfait par votre achat, si vous souhaitez nous retourner un produit en accord avec nos conditions générales de vente voici les conditions à remplir :",
    "description list 5": "Tous les articles doivent être dans leur emballage d'origine avec les étiquettes de produit intactes.",
    "description list 6": "Tous les articles doivent être non portés, non utilisés, non lavés et dans leur état d'origine.",
    "description list 7": "Les reçus originaux devraient être inclus.",
    "description returns 2": "Rendez-vous sur votre compte et depuis l'historique de vos commandes initiez un retour et contactez nos équipes. Les retours sont à votre charge sauf en cas de produit défectueux.",
  "Defective Product": "Produit défectueux",
  "Storelocator1": 'Les boutiques Promulias à Paris',
  "See my order": 'Voir ma commande',
  "Storelocator-text": 'Nous vous accueillons chaleureusement au sein de nos boutiques de robe de mariée à Paris Promulias Sentier et Promulias Popincourt. L’idéal est de réserver un créneau à l’avance pour une séance d’essayage dans nos showrooms pour que notre équipe sur place puisse prendre le temps de vous conseiller. ',
  "Storelocator2": 'Les boutiques de nos revendeurs hors de Paris',
  "Storelocator-text2":  "Si vous n’habitez pas en région parisienne, pas de panique ! De manière à rendre nos collections de robes accessibles à tout un chacun, nous collaborons avec des boutiques de revendeurs en province dans de nombreuses villes de France. Vous trouverez sans aucun doute une boutique à proximité de chez vous. Découvrez la liste de nos boutiques partenaires.",
  "locate_me": "Me localiser",
  "Promulias_Shop": "Boutique Promulias",
  "Partner_Shop": "Boutique partenaire",
  "Store_find": "Boutiques trouvées : ",
  "Schedule": "Horaires",
  "Week": "Du lundi au vendredi de 9h à 19h",
  "Week-end": "Le samedi de 9h à 20h",
  "Sunday": "Fermé le dimanche",
  "No_Favorite_Yet": "Pas encore de favoris",
  "Add_Favorites": "Sélectionnez votre article préféré en cliquant sur le coeur. Vous pourrez le retrouver ici.",
  "No answers to your questions? Chat with us on WeChat! Write to us! Contact us now!": "Pas de réponses à vos questions ? Echangez avec nous sur WeChat ! Ecrivez-nous ! Contactez-nous !",
  "Whatsapp": "Nous rejoindre sur WhatsApp",
  "Whatsapp Text": 'Besoin de réponses rapides ? Posez-nous vos questions sur WhatsApp ! Ecrivez-nous !',
  "WeChat QRCode": 'Scannez le QR Code !',
  "Topic": "Objet",
  "Order": 'Commande',
  "Go back": "Retour",
  "Quest?": 'Question',
  "Message": "Message",
  "Submit": "Envoyer",
  "Invalid_email": "L'adresse e-mail renseignée ne semble pas correcte",
  "Invalid_phone_number": "Le numéro de téléphone renseignée semble incorrect",
  "Accept CGU": 'Veuillez accepter les Conditions Générales d\'Utilisation',
  "Select_a_choice": 'Veuillez selectionner une catégorie',
  "Promulias in Milan": 'Promulias à Milan',
  "Our story": 'Notre histoire',
  "Promulias in Cologne": 'Promulias à Cologne',
  "Our presence in Cologne": 'Notre présence à Cologne',
  "faq": "Foire Aux Questions",
  "Category": "Catégories",
  "Categories of questions": 'Catégories',
  "Answers to your questions by category": 'Les réponses à vos questions par catégories :',
  "Join us on WeChat": "Nous joindre sur WeChat",
  "Contact Text FAQ": 'Pas de réponses à vos questions ? Echangez avec nous sur WhatsApp ! Ecrivez-nous !',
  "Page_not_found": "Nous ne trouvons pas la page",
  "We can't find products matching the selection.":"Nous ne trouvons pas de produits correspondant à la sélection.",
  "Sadly": "Malheureusement, nous n'avons pas trouvé la page que vous recherchez.",
  "Back to homepage": "Retour à l'accueil",
  "return to last page": 'Retour sur la dernière page',
  "Find my order": 'Retrouver ma commande',
  "Accept cookies": 'Accepter les cookies',
  "Cookies text": 'Nous utilisons des ',
  "Cookies": 'cookies',
  "Cookies text2": ' qui aident le site web à fonctionner et nous aident à comprendre comment interagir avec lui. Nous utilisons ces cookies pour vous offrir une expérience utilisateur améliorée et personnalisée. Si vous continuez à utiliser le site web, nous supposons que vous êtes d\'accord.',
  "Accept": 'Accepter',
  "The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number": "Votre mot de passe doit contenir au minimun 8 caractères, 1 majuscule, 1 minuscule et 1 chiffre.",
  "This field email is required": 'Veuillez fournir une adresse e-mail valide.',
  "This field password is required": 'Ce champs est requis. Veuillez renseigner votre mot-de-passe.',
  "We do not find an account associated with the e-mail address and/or the password entered. Make sure that the correct entry of your information.": 'Nous ne retrouvons pas de compte client associé à l\'adresse e-mail et/ou au mot de passe renseigné. Assurez-vous de la bonne saisie de vos informations.',
  "About_us": "Qui sommes-nous ?",
  "About_subtitle1": "Nos valeurs, nos engagements",
  "About_subtitlecontent1": "Promulias est une entreprise innovante et créative fondée à Paris par un directeur général dynamique déjà présent dans plusieurs pays européens.",
  "About_subtitlecontent2": "Promulias diffère largement de ses concurrents avec un grand nombre de produits dans une gamme complète de tailles et de couleurs (robes de cocktail et de soirée).",
  "About_subtitlecontent3": "Comme son créateur, Promulias est très à l'écoute de ses clients.",
  "About_subtitlecontent4": "Son équipe s'inscrit dans la même lignée que celle du fondateur, avec un suivi qualité très sérieux, de la sélection des modèles jusqu'à la fabrication.",
  "About_subtitlecontent5": "De plus, la sélection des distributeurs contribue à la création d'un réseau de qualité.",
  "About_subtitlecontent6": "Promulias n'est qu'au début d'une belle histoire dont les plus belles pages seront écrites avec vous.",
  "Add to compare": "Ajouter pour comparer",
  "Add to Wishlist": "Ajouter à la whitelist",
  "Additional Information": "Informations supplémentaires",
  "Allow order notifications": "Autoriser les notifications de commande",
  "Apply": "Appliquer",
  "Loyalty points": "Des points fidélité",
  "Attention!": "Attention!",
  "Go_back": "Retour à la dernière page",
  "Back to home": "Retour à la page d'accueil",
  "Billing": "Facturation",
  "Brand": "Marque",
  "Cancel": "Annuler",
  "Or log in with :": "Ou se connecter avec",
  "Change password your account": "Changer votre mot de passe",
  "Clear all": "Effacer tout",
  "Commercial information": "et je suis d'accord pour recevoir des informations commerciales de Promulias.",
  "Contact details updated": "Gardez vos informations de contact et de paiement à jour",
  "Continue to billing": "Continuer vers la facturation",
  "Continue to payment": "Continuer vers le paiement",
  "Continue to shipping": "Continuer vers l'expédition",
  "Create an account": "Créer un compte",
  "Customer Reviews": "Avis des clients",
  "Customer service": "Service client",
  "Departments": "Départements",
  "Description": "Description",
  "Details and status orders": "Vérifiez les détails et le statut de vos commandes depuis cet historique de commandes. Vous pouvez également demander un retour de votre commande.",
  "Done": "Finie",
  "Download": "Télécharger",
  "Download all": "Tout télécharger",
  "Edit": "Modifier",
  "Email_address": "Adresse email",
  "Empty": "Il semblerait que votre panier soit vide. Commencez à acheter pour le remplir.",
  "Empty bag": "Panier vide",
  "Enjoy your free account": "Profitez de ces avantages avec votre compte gratuit !",
  "Feedback": "Votre avis compte pour nous. Faîtes nous savoir comment nous pouvons nous améliorer.",
  "Filters": "Filtres",
  "Find out more": "En savoir plus",
  "forgotPasswordConfirmation": "Merci! Si un compte est enregistré avec l'e-mail {0}, vous trouverez un message avec un lien de réinitialisation du mot de passe dans votre boîte de réception.",
  "Go back shopping": "Retour au shopping",
  "Go back to shop": "Retour à la boutique",
  "Guarantee": "Garantie",
  "hide": "Cacher",
  "I agree to": "J'accepte",
  "I confirm subscription": "Je confirme ma souscription",
  "Info after order": "Vous pouvez vous connecter à votre compte en utilisant l'e-mail et le mot de passe définis précédemment. Sur votre compte, vous pouvez modifier les données de votre profil, vérifier l'historique des transactions, modifier l'abonnement à la newsletter.",
  "Instruction2": "Juste ici pour les instructions d'entretien ?",
  "Instruction3": "Ouais, nous le pensions",
  "It was not possible to request a new password, please check the entered email address.": "Il n'a pas été possible de demander un nouveau mot de passe, veuillez vérifier votre adresse email.",
  "Item": "Article",
  "Items": "Articles",
  "Kidswear": "Vêtements pour enfants",
  "Let’s start now – we’ll help you": "Commençons maintenant – nous vous aiderons",
  "Log into your account": "Connectez-vous à votre compte",
  "login in to your account": "Connectez-vous à votre compte",
  "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.": "Il semblerait que vous n'ayez pas encore ajouté d'articles au panier. Commencez à acheter pour le remplir.",
  "Make an order": "Commander",
  "Manage addresses": "Gérez toutes les adresses de livraison que vous souhaitez (lieu de travail, adresse personnelle, etc.). Ainsi, vous ne devrez pas saisir manuellement l'adresse de livraison pour chaque commande.",
  "Manage shipping addresses": "Gérez toutes les adresses de livraison que vous souhaitez (lieu de travail, domicile...) Ainsi vous n'aurez pas à saisir manuellement l'adresse de livraison à chaque commande.",
  "We did not find anything for": "Nous n'avons rien trouvé pour",
  "Match it with": "Correspond à",
  "Men fashion": "Mode homme",
  "My Cart": "Mon panier",
  "New_Products": "Nouveaux produits",
  "or": "ou",
  "or fill the details below": "ou remplissez les détails ci-dessous",
  "Other products you might like": "D'autres produits que vous pourriez aimer",
  "Out of stock": "Rupture de stock",
  "Password Changed": "Le mot de passe a été modifié avec succès. Vous pouvez maintenant revenir à la page d'accueil et vous connecter.",
  "Payment & Delivery": "Paiement & Livraison",
  "Payment methods": "Méthodes de paiement",
  "Personal details": "Détails personnels",
  "Please type your current password to change your email address.": "Veuillez saisir votre mot de passe actuel pour modifier votre adresse email.",
  "Invalid_First_Name": " Prénom invalide",
  "Invalid_Last_Name": " Nom invalide",
  "Open": "Ouvrir",
  "Privacy": "Confidentialité",
  "Confidentiality": "Politique de confidentialité des données",
  "Product suggestions": "Suggestions de produits",
  "Products": "Produits",
  "Products found": "Produits trouvés",
  "Purchase terms": "Conditions d'achat",
  "Quality in the details": "Qualité dans les détails",
  "Read and understand": "J'ai lu et compris la",
  "Privacy newsletter" : "Politique de confidentialité des données",
  "Read reviews": "Avis",
  "Remove Address": "Supprimer l'adresse",
  "Remove from Wishlist": "Supprimer de la wishlist",
  "Reset_Password": "Réinitialiser le mot de passe",
  "Review my order": "Vérifier ma commande",
  "Same as shipping address": "Identique à l'adresse de livraison",
  "Save changes": "Valider",
  "Save for later": "Enregistrer pour plus tard",
  "Search": "Rechercher",
  "Search for items": "Rechercher des articles",
  "Search results": "Résultats de la recherche",
  "Sections that interest you": "Sections qui vous intéressent",
  "See all results": "Voir tous les résultats",
  "Select payment method": "Sélectionner la méthode de paiement",
  "Select shipping method": "Sélectionner la méthode de livraison",
  "Send my feedback": "Envoyer mon avis",
  "Set up newsletter": "Souhaitez-vous découvrir nos collections, recevoir des promotions exclusives et participer à nos événements ? Pour vous désinscrire, un lien en bas de chaque mail vous permettra de vous désabonner.",
  "Sign Up for Newsletter":"Je veux recevoir la newsletter",
  "Share your look": "Partagez votre look",
  "Shipping details": "Détails de livraison",
  "Show":"Montrer",
  "Show more": "Voir plus",
  "Show less": "Voir moins",
  "Show on page": "Afficher sur la page",
  "Sort by": "Trier par",
  "Sort: Default": "Par défaut",
  "Sort: Name A-Z": "Par nom A-Z",
  "Sort: Name Z-A": "Par nom Z-A",
  "Sort: Price from high to low": "Par prix décroissant",
  "Sort: Price from low to high": "Par prix croissant",
  "Start shopping": "Commencez à acheter",
  "Subscribe": "Souscrire",
  "Subscribe to newsletter": "S'inscrire à la newsletter",
  "subscribeToNewsletterModalContent": "Après vous être inscrit à la newsletter, vous recevrez des offres spéciales et des messages de Promulias par e-mail. Nous ne vendrons ni ne distribuerons votre e-mail à aucun tiers à aucun moment. Veuillez consulter notre {0}.",
  "Subtotal price": "Prix sous-total",
  "Successful placed order": "Vous avez passé la commande avec succès. Vous pouvez vérifier l'état de votre commande en utilisant notre fonction d'état de livraison. Vous recevrez un e-mail de confirmation de commande avec les détails de votre commande et un lien pour suivre son évolution.",
  "Thank You Inbox": "Si le message n'arrive pas dans votre boîte de réception, essayez une autre adresse e-mail que vous avez peut-être utilisée pour vous inscrire.",
  "Total items": "Total articles",
  "Total price": "Prix total",
  "Updates password": "Mettre à jour le mot de passe",
  "Update personal data": "Mettre à jour les données personnelles",
  "User Account": "Compte utilisateur",
  "View": "Voir",
  "View details": "En savoir plus",
  "Vue Storefront Next": "Vue Storefront Next",
  "Who we are": "Qui nous sommes",
  "Women fashion": "Mode femme",
  "You are not authorized, please log in": "Vous n'avez pas l'authorisation, veuillez vous connecter",
  "You can unsubscribe at any time": "Vous pouvez vous désabonner à tout moment",
  "You haven\’t searched for items yet": "Vous n'avez pas encore recherché d'articles",
  "Your current email address is": "Votre adresse email actuelle est",
  "The email field must be a valid email": "Le champ email doit être une adresse email valide",
  'You have submitted no reviews': 'Vous n\'avez pas encore soumis de commentaires',
  "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.": "La connexion au compte était incorrecte ou votre compte est temporairement désactivé. Veuillez patienter et réessayer plus tard.",
  "A customer with the same email address already exists in an associated website.": "Un client avec la même adresse e-mail existe",
  "Invalid email": "Email invalide",
  "SUMMER COLLECTION {year}": "COLLECTION ÉTÉ {year}",
  "Colorful summer dresses are already in store": "Les vêtements d'été colorés sont déjà en magasin",
  "Learn more": "En savoir plus",
  "Dresses": "Vêtements",
  "Cocktail & Party": "Cocktail & Fête",
  "Linen Dresses": "Vêtements de lin",
  "T-Shirts": "T-Shirts",
  "The office life": "La vie de l'agence",
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.": "Trouvez de superbes robes de cocktail et robes de soirée pour femmes. Démarquez-vous avec des robes de cocktail en dentelle et métallisées de toutes vos marques préférées.",
  "Shop now": "Achetez maintenant",
  "Summer Sandals": "Sandales d'été",
  "Eco Sandals": "Sandales écologiques",
  "Subscribe to Newsletters": "S'inscrire à la newsletter",
  "Be aware of upcoming sales and events. Receive gifts and special offers!": "Soyez au courant des ventes et événements à venir. Recevez des cadeaux et des offres spéciales!",
  "Fashion to take away": "Mode à prendre",
  "Download our application to your mobile": "Téléchargez notre application sur votre mobile",
  "Share Your Look": "Partagez votre look",
  "Personal Details": "Détails personnels",
  "My reviews": "Mes commentaires",
  "My wishlist": "Ma wishlist",
  "Password change": "Changement de mot de passe",
  "Personal data": "Données personnelles",
  "Your e-mail": "Votre e-mail",
  "You are not authorized, please log in.": "Vous n'êtes pas autorisé, veuillez vous connecter.",
  "Go To Product": "Aller au produit",
  "Change to list view": "Changer en vue liste",
  "Change to grid view": "Changer en vue grille",
  "Please select a country first": "Veuillez d'abord sélectionner un pays",
  "The field should have at least 2 characters": "Le champ doit contenir au moins 2 caractères",
  "The field should have at least 4 characters": "Le champ doit contenir au moins 4 caractères",
  "The field should have at least 10 characters": "Le champ doit contenir moins de 10 caractères",
  "The field should have at least 50 characters": "Le champ doit contenir moins de 50 caractères",
  'The field should have less than 35 characters': 'Le champ doit contenir moins de 35 caractères',
  "New Products": "Nouveaux produits",
  "no shipping methods available": "Il n'y a pas de méthodes d'expédition disponibles pour ce pays. Nous sommes désolés, veuillez essayer avec un autre pays.",
  "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.": "Une erreur s'est produite lors de la tentative de récupération des méthodes d'expédition. Nous sommes désolés, veuillez essayer avec des détails d'expédition différents.",
  "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.": "Une erreur s'est produite lors de la sélection de ce mode d'expédition. Nous sommes désolés, veuillez essayer avec une méthode d'expédition différente.",
  "AllProductsFromCategory": "Tout les produits",
  "About": 'À propos',
  "Your item is successfully added to cart.": "Votre article est bien ajouté au panier.",
  'Your item is not successfully added to cart.': 'Ce produit n\'est plus disponible, veuillez choisir une autre couleur ou taille',
  "Discover Promulias": "Découvrez promulias",
  "Sorry, this product is no longer available": "Désolé, ce produit n’est plus disponible",
  "CDG": "CGV",
  "GUC": "CGU",
  "Sed": "Sed porttitor lectus nibh ?",
  "Lorem": "Nulla quis lorem ut libero malesuada feugiat. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Vivamus suscipit tortor eget felis porttitor volutpat.",
  "Subtitle connect": "et accéder au site professionnel Promulias",
  "Required_1": "Il doit contenir au minimum 8 caractères, 1 majuscule,",
  "Required_2": "1 minuscule et 1 chiffre.",
  "Passwords_do_not_match": "Les mots de passe ne correspondent pas",
  "invalid_Password_Msg": "Mot de passe invalide",
  "Find_order": "Recherche de la commande",
  "subcontentFO_1": "Recherche une seule commande en saisissant votre numéro de commande et l'adresse e-mail utilisée pour passer lma commande en ligne ou en magasin",
  "Reset_Password": "Réinitialiser le mot de passe",
  "subcontentFO_1": "Recherche une seule commande en saisissant votre numéro de commande et l'adresse e-mail utilisée pour passer ma commande en ligne ou en magasin",
  "Passwords_do_not_match": "Les mots de passe ne correspondent pas",
  "Required_1": "Le mot de passe doit contenir au moins 8 caractères, dont une majuscule",
  "Required_2": "1 minuscule et un nombre.",
  "invalid_Password_Msg": "Le mot de passe saisie est invalide",
  "No answers ?": "Vous ne trouvez pas de réponse à vos questions ?",
  "Join to WeChat": "Nous joindre sur WeChat",
  "store-locator_subcontent": "Nulla quis lorem ut libero malesuada feugiat. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Curabitur aliquet quam id dui posuere blandit. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.",
  "zip": "Code postal",
  "Invalid postalCode":"Code postal non valide",
  "type_zip_or_town": "Renseigner un code postal",
  "Appointment": " Prendre rendez-vous",
  "away_from_you": " de distance",
  "Male": "Homme",
  "Female": "Femme",
  "Not Specified": "Non précisé",
  "Register": "Enregistrer",
  "If you want to change your password": "Si vous voulez changer le mot de passe pour accéder à votre compte, entrez les informations suivantes : votre adresse électronique actuelle est",
  "Modify profile": "Modifier profil",
  "Register": "Inscription",
  "Sign up": "S'enregistrer",
  "Choose Shipping": "Choisir ma livraison",
  "Protected package": "Colis protégé",
  "Earn points on all your purchases": "Cumulez vos points sur tous vos achats",
  "Invalid name": "Nom invalide",
  "Password confirmation does not match": "Les mots de passe ne correspondent pas",
  "Or": "Ou",
  "Wishlist_description": "Nulla quis lorem ut libero malesuada feugiat. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Curabitur aliquet quam id dui posuere blandit. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.",
  "Country code": "Code pays",
  'Chose a country': 'Choisir un pays',
  "Example :" : "Exemple :",
  "payment_failed": "Paiement échoué",
  "Payment being processed": "Paiement en cours de traitement",
  "Paypal is temporarily unavailable": "Paypal est momentanément indisponible",
  "Name Adress": "Nom de cette adresse",
  "Street Name": "rue",
  "Country code": "Code pays",
  'Chose a country': 'Choisir un pays',
  'Phone number': 'Numéro de téléphone',
  "Example :" : "Exemple :",
  "and more" : "et plus",
  "card number": "numéro de carte",
  "month": "mois",
  "year": "année",
  "invalid": "invalide",
  "Please choose a month": "Veuillez choisir un mois",
  "An error occurred during payment": "Votre paiement est refusé, veuillez vérifier les informations saisies ou utiliser un autre moyen de paiement",
  "The card has expired": "La carte est expirée",
  "delivery_informations" : "Informations de livraison",
  "delivery_informations_text" : "Vous trouverez ici, toutes nos solutions de livraison à domicile ou en point relais.",
  "delivery_price" : "Livraison gratuite dès X€",
  "metropolitan_France_only" : "France métropolitaine uniquement.",
  "corse" : "Livraison en Corse:complément de 3€ de délai supplémentaire de 2jours.",
  "mondial-relay_delivery" : "Livraison standard en point relais - 3 à 5jours(ouvrés) - 4,90€",
  "colissimo_delivery" : "Livraison standard à domicile - 2 à 4 jours(ouvrés) - 5,90€.",
  "chronopost_delivery": "Livraison rapide à domicile - 24 à 72h(jours ouvrés) - 9,90€.",
  "Particular" : "Je suis un particulier",
  "Professional" : "Je suis un professionnel",
  "Get_An_Alert" : "Recevoir une alerte",
  "Contact_us": "Contactez-nous",
  "Rejoin us on WeChat":"Rejoignez-nous sur WeChat",
  "This_email_is_already_register": "Votre adresse email est déja enregistré ou est invalide",
  "Subscribe_to_newsletter_body": "Veuillez inscrire votre adresse email dans le champ ci contre",
  "error_contact": "Une erreur est survenue lors de l'envoi du message",
  "error": "Une erreur est survenue",
  "Order return": "Retour de commande",
  "Conform Product": "Produit conforme à mes attentes",
  "Defective Product": "Produit défectueux",
  "Have a question about a return?": "Vous avez une question concernant un retour ?",
  "WhatsApp Title": "Démarrez votre retour via WhatsApp",
  "Chat Content": "Notre service client vous réponds et vous accompagne de 9h à 18h du lundi au vendredi",
  "WeChat Title": "Démarrez votre retour via WeChat",
  "Start your return via WeChat": "Démarrez votre retour via WeChat",
  "Your message has been sent": "Votre message a bien été envoyé",
  "Your message has not been sent": "Votre message n'a pas été envoyé",
  "you_have_been_register": 'Votre email a été enregistré.',
  "Title": 'Titre',
  "Review": 'Commentaire',
  "Rating": 'Note',
  "Soy first to leave a review": 'Soyez le premier à laisser un avis',
  "Add review": 'Ajouter',
  "Delivery Free": 'Livraison gratuite',
  "Use this address as my default one.": 'Utiliser cette adresse par defaut.',
  "The field should have at least": 'Ce champs ne peut contenir que ',
  "characters": ' caractères',
  "CVV is required": 'Le CVV est requis',
  "Yes, i delete this article": 'Oui, je supprime cet article.',
  "Explore now": 'Explorer',
  "Prestige dress": 'Robe prestige',
  "Ribbon dress":'Robe à ruban',
  "Wedding": 'Mariage',
  "Accessory": 'Accessoires',
  "Discover Wedding Dress": "Découvrez notre collection de robe de mariée",
  "Wedding description": "Chercher sa robe de mariée est une aventure aussi exaltante que complexe ! Notre boutique de robe haut de gamme vous propose des modèles de prêt-à-porter adaptables à toutes morphologies et aux finitions précise",
  "faq-legend": "Vous avez une question relative à votre achat ou vos produits Promulias qui vous brûle les lèvres ? Gagnez du temps en trouvant une réponse en quelques clics. Pour toute question concernant vos données personnelles et obtenir des instructions sur l’accès ou la suppression de vos données conservées, consultez notre ",
  "Modify my profile": "Modifier mon profil",
  "We can't find products matching the selection." : "Nous ne trouvons pas de produits correspondant à la sélection.",
  "Show": "Montrer",
  "There was some error while trying to select this shipping method. We are sorry, please try with a different shipping method.": 'Il y a eu une erreur en essayant de sélectionner cette méthode d’expédition. Nous sommes désolés, veuillez essayer avec une méthode d’expédition différente.',
  "Online, Promulias offers you a wide range of dresses and accessories.": 'En ligne, Promulias vous offre une large gamme de robes et d\'accessoires.',
  "Through our wide catalogue of prestige dresses, collections dresses, ribbon dresses, wedding dresses, dresses for events, gala dresses, evening dresses, cocktail dresses, family party dresses, Dresses in promotion, dresses novelties the occasion will be given you to choose the style, the color or the size of your dress through the many photos, filters and details of our products.": 'Au travers de notre large catalogue de robes prestige, robes collections, robes à ruban, robes de mariage, robes pour des évènements, robes de gala, robes de soirée, robes de cocktail, robes de fêtes familiales, robes en promotion, robes nouveautés l\'occasion vous sera donnée de choisir le style, la couleur ou encore la taille de votre robe au travers des nombreuses photos, de filtres et de détails de nos produits.',
  "Discover our coloured chiffon, silk or lace dresses and choose the right length!": 'Découvrez nos robes de couleur en mousseline, en soie ou en dentelle et choisissez la longueur adaptée !',
  "The advantage of buying your wedding dress online at Promulias is to benefit from the largest choice of size and silhouette but also from free shipping throughout France.": 'L\'avantage d\'acheter votre robe de mariée en ligne chez Promulias, c\'est de bénéficier du plus grand choix de taille et de silhouette mais aussi de la livraison gratuite dans toute la France.',
  "The Promulias customer service is available directly on whatsapp and wechat and answers you very quickly. Choosing a Promulias dress is opting for a designer dress at the price of ready-to-wear.": 'Le service client Promulias est disponible directement sur whatsapp et wechat et vous repond très rapidement. Choisir une robe Promulias c’est opter pour une robe de créateur au prix du prêt-à-porter.',
  "Besides a nice collection of wedding and evening dresses, we offer for sale a wide selection of accessories and especially jewelry and shoes to refine your wedding outfit.": 'Outre une jolie collection de robes de mariée et de soirée, nous proposons à la vente une large sélection d’accessoires et notamment de bijoux et de chaussures pour peaufiner votre tenue de mariage.',
  "Thanks to the different viewing angles of the photos, you can more easily project yourself.": 'Grâce aux différents angles de vues des photos, vous pouvez plus facilement vous projeter.',
  "You have a question, need a review or recommendation, help on a product or your order, find here the best way to contact us.": 'Vous avez une question, besoin d\'un avis ou d\'un recommandation, d\'aide sur un produit ou votre commande, retrouvez ici le meilleur moyen de nous contacter.',
  "My Account": "Mon compte",
  "Addresses details": "Détails des adresses",
  'Page not found': 'Nous ne trouvons pas la page',
  'Start your shopping': 'Commencer votre shopping',
  'By validating your order, you acknowledge having read the': 'En validant votre commande, vous reconnaissez avoir pris connaissance des',
  "and you accept the terms.": "et vous acceptez les conditions.",
  "Go back to shipping address": "retourner à l'adresse de livraison",
  "Your password has been successfully updated!": 'Votre mot de passe a été mis à jour avec succès !',
  "Passwords don't match": 'Les mots de passe ne correspondent pas',
  "Privacy newsletter": "Politique de confidentialité des données",
  "ssoError": "Votre adresse email est déja associé à un compte précedemment crée via la page d'inscription Promulias, veuillez vous connecter via le formulaire de connexion classique.",
  "colorSelect": 'Couleur selectionnée',
  "all field required": 'Tous les champs sont requis',
  "Quick delivrey description": 'Dans toute l\'Union Européenne*',
  "Protected parcels description": 'Emballages solides et sécurisés',
  "Secure payment description": 'Transaction protégé par Stripe',
  "Current Email": "Votre adresse électronique actuelle",
  "About": "À propos",
  "remaining product.": "produit restant.",
  "remaining products.": "produits restants.",
  "Applied Coupon": "Coupon appliqué",
  "Out of stock!": "En rupture de stock !",
  "Invalid number": 'Numéro invalide',
  "Tracking number": "Numéro de suivi",
  "notification save": "Modification enregistrée",
  "Reorder-asking": "Renouveler la commande",
  "Reorder-cancel": "Annuler",
  "Reorder-agree": "Confirmer",
  "Reorder-number": "Numéro de commande : ",
  "Reorder": "Renouveler la commande",
  "Shipping address": "Adresse de livraison",
  "Are you sure you want to empty your cart?": "Êtes-vous sûr de vouloir vider votre panier ?",
  "Yes, I empty my basket": "Oui, je vide mon panier",
  "One is missing the size of your product" : 'Veuillez selectionner la taille de votre produit',
  "Sold out": '- Épuisé',
  "mail not existe": 'Cette adresse email n\'existe pas, pour faire une demande d\'alerte, merci de bien vouloir vous connecté ou de vous inscrire.',
  "error product": 'Une erreur a été détectée lors de la récupération du produit, merci de réessayé ultérieurement.',
  "succes subscribe alert stock": 'Vous recevrez une alerte lors de la remise en stock.',
  "error subscribe alert stock": 'Une erreur est survenue, merci de vérifier votre adresse ou de réessayé ultérieurement.',
  "Receive alert stock": 'recevoir une alerte stock',
  "alert me to stock": 'Recevoir une alerte de remise en stock',
  "stock01": "Attention ! Stock Limité, Seulement",
  "restant":  "restant",
  "restants":  "restants",
  "show color and size": "Seules les tailles et couleurs en stock sont affichées.",
  "Account already exists. ": "Le compte existe déjà. ",
  'Order is being generated':'La commande est en cours de génération...',
  'Mail is being sent': 'Le courrier est en cours d\'envoi...',
  "News": "Nouveauté",
  "Long dresses": "Robes Longues",
  "Short dresses": "Robes Courtes",
  "Ou se connecter avec :": "Ou se connecter avec :",
  "Birthday invalid":"Date de naissance invalide",
}
