import { Logger } from '~/helpers/logger';
import { VsfContext } from '~/composables/context';

export const loadContentCommand = {
  execute: async (context: VsfContext, params) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const { data } = await context.$magento.api.cmsPage(params.identifier);

    return data.cmsPage;
  },
};
