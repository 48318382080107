import putLastLoginException from '~/exceptions/putLastLogin.exception';
import axios from 'axios'

export class LastLogin {
    constructor() {}
        readonly baseUrlOrderHistoryImage = process.env.VSF_STORE_URL;
        readonly baseUrlStore = process.env.VSF_STORE_URL;
        readonly headers = {
            "Content-Type" : 'application/json'
        }

        public async putLastLogin(id: Readonly<string>){

            try {
                const adminToken = await axios.post(`${this.baseUrlStore}getAdminToken`);
                const response = await axios.put(`${ this.baseUrlStore }putCustomer?id=${id}&adminToken=${adminToken.data}`);
              return response;
            }
            catch (error){
                throw new putLastLoginException(JSON.stringify(error));
            }
        } 
}