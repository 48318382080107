import { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';
import { CreateProductReviewInput } from '~/modules/GraphQL/types';
import { ComposableFunctionArgs } from '~/composables/types';

export const addReviewCommand = {
  execute: async (context: VsfContext, params: ComposableFunctionArgs<CreateProductReviewInput>) => {

    const {
      customQuery,
      ...input
    } = params;

    const { data } = await context.$magento.api.createProductReview(input);


    return data?.createProductReview?.review ?? {};
  },
};
