import axios, { AxiosResponse } from 'axios';
export class ProductRepository {
  constructor() {}
  readonly baseUrlStore = process.env.VSF_STORE_URL;

  public async getAllProducts(pageInfo) {
    try {
      const response = await axios.post(`${this.baseUrlStore}getAllProducts`, {
        categoryId: pageInfo.categoryId,
        currentPage: pageInfo.currentPage,
        pageSize: pageInfo.pageSize,
        sortField: pageInfo.sortField,
        sortValue: pageInfo.sortValue,
      });
      return response.data;
    } catch (error) {
      throw JSON.stringify(error);
    }
  }
  // public async getProducts(pageInfo) {
  //   try {
  //     const response = await axios.post(`${this.baseUrlStore}getProducts`, {
  //       categoryId: pageInfo.categoryId,
  //       currentPage: pageInfo.currentPage,
  //       pageSize: pageInfo.pageSize,
  //       sortField: pageInfo.sortField,
  //       sortValue: pageInfo.sortValue,
  //     });
  //     return response.data;
  //   } catch (error) {
  //     throw JSON.stringify(error);
  //   }
  // }

  // public async getProductsGraphql(pageInfo) {
  //   try {
  //     const response = await axios.post(`${this.baseUrlStore}getProductsGraphql`, {
  //       categoryId: pageInfo.categoryId,
  //       currentPage: pageInfo.currentPage,
  //       pageSize: pageInfo.pageSize,
  //       sortField: pageInfo.sortField,
  //       sortValue: pageInfo.sortValue,
  //     });
  //     return response.data;
  //   } catch (error) {
  //     throw JSON.stringify(error);
  //   }
  // }

  public async getProductSku(sku) {
    try {
      const adminToken = await axios.post(`${this.baseUrlStore}getAdminToken`);
      const response = await axios.get(`${this.baseUrlStore}getProductSku?sku=${sku}&adminToken=${adminToken.data}`);
      return response.data;
    } catch (error) {
      throw JSON.stringify(error);
    }
  }

  public async getAllChildBySkuParent(sku) {
    try {
      const adminToken = await axios.post(`${this.baseUrlStore}getAdminToken`);
      const response = await axios.get(`${this.baseUrlStore}getAllChildBySkuParent?sku=${sku}&adminToken=${adminToken.data}`);
      return response.data;
    } catch (error) {
      throw JSON.stringify(error);
    }
  }
}
