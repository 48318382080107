import { ComposableFunctionArgs, CustomerProductReviewParams } from '~/composables/types';
import { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';

export const loadCustomerReviewsCommand = {
  execute: async (context: VsfContext, params?: ComposableFunctionArgs<CustomerProductReviewParams>) => {

    const { data } = await context.$magento.api.customerProductReview(params);


    return data?.customer ?? {};
  },
};
