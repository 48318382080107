import { readonly, ref } from '@nuxtjs/composition-api';
import type { ComposableFunctionArgs } from '~/composables/types';
import useApi from '~/composables/useApi';
import { SortingOptions } from '~/modules/catalog/category/composables/useFacet/SortingOptions';
import { PerPageOptions } from '~/modules/catalog/category/composables/useFacet/PerPageOptions';
import { createProductAttributeFilterInput } from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeFilterInput';
import { createProductAttributeSortInput } from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeSortInput';
import { Products } from '~/modules/GraphQL/types';
import GetFacetDataCustomQuery from './getFacetDataCustom.gql';
import getNumberOfProduct from './getNumberOfProduct.gql';
import type { UseFacetInterface, UseFacetErrors, UseFacetSearchResult, FacetSearchParams } from './useFacet';
import { ProductRepository } from '~/repository/products.repository';

/**
 * The `useFacet()` composable allows searching for products using facets.
 *
 * What makes it powerful is the ability to accept multiple filters, allowing to
 * narrow down the results to a specific category, search term, etc.
 */
export function useFacet(): UseFacetInterface {
  const { query } = useApi();
  const loading = ref(false);
  const result = ref<UseFacetSearchResult>({ data: null, input: null });
  const error = ref<UseFacetErrors>({
    search: null,
  });
  const defaultItemsPerPage = 24;
  const search = async (params?: ComposableFunctionArgs<FacetSearchParams>) => {
    result.value.input = params;
    try {
      loading.value = true;
      const isNotFilter = Object.keys(params.filters).length === 0;
      const pageSize = params.itemsPerPage ? params.itemsPerPage : defaultItemsPerPage;
      const sort = createProductAttributeSortInput(params.sort || 'position_ASC');
      const sortField = Object.keys(sort).toString();
      const sortValue = Object.values(sort).toString();
      if (isNotFilter && !params.sort.includes('price')) {
        const productRepository = new ProductRepository();
        let sortProduct;
        // try {
          // const getProductRequest = productRepository.getProducts({
          //   categoryId: createProductAttributeFilterInput(params).category_id.in[0],
          //   currentPage: params.page,
          //   pageSize: pageSize,
          //   sortField: sortField,
          //   sortValue: sortValue,
          // });
          // const timeoutPromise = new Promise((resolve) => {
          //   setTimeout(resolve, 5000);
          // });
          // sortProduct = await Promise.race([getProductRequest, timeoutPromise]);
          // if (!sortProduct) {
            // sortProduct = await productRepository.getAllProducts({
            //   categoryId: createProductAttributeFilterInput(params).category_id.in[0],
            //   currentPage: params.page,
            //   pageSize,
            //   sortField,
            //   sortValue,
            // });
          // }
        // } catch (e) {
          sortProduct = await productRepository.getAllProducts({
            categoryId: createProductAttributeFilterInput(params).category_id.in[0],
            currentPage: params.page,
            pageSize: pageSize,
            sortField: sortField,
            sortValue: sortValue,
          });
        // }
        const skus = sortProduct.items.map((item) => item.sku);
        const filterSku = {
          sku: {
            in: skus,
          },
        };
        const productSearchParams = {
          pageSize,
          search: params.term ? params.term : '',
          filter: skus.length > 0 ? filterSku : createProductAttributeFilterInput(params),
          sort: createProductAttributeSortInput(params.sort || ''),
          currentPage: 1,
        };
        const productSearchParamsPaginate = {
          pageSize,
          search: params.term ? params.term : '',
          filter: createProductAttributeFilterInput(params),
          sort: createProductAttributeSortInput(params.sort || ''),
          currentPage: params.page,
        };
        let productsList;
        let products;
        // try {
          // const getFacetProductRequest = await productRepository.getProductsGraphql({
          //   categoryId: createProductAttributeFilterInput(params).category_id.in[0],
          //   currentPage: params.page,
          //   pageSize: pageSize,
          //   sortField: sortField,
          //   sortValue: sortValue,
          // });
          // const timeoutPromise = new Promise((resolve) => {
          //   setTimeout(resolve, 5000);
          // });
          // productsList = await Promise.race([getFacetProductRequest, timeoutPromise]);
          // if (productsList && productsList.data && productsList.data.products) {
          //   products = productsList.data.products;
          // } else {
            // const { products: errorProducts } = await query<{ products: Products }>(GetFacetDataCustomQuery, productSearchParams);
            // products = errorProducts;
          // }
        // } catch (e) {
          const { products: errorProducts } = await query<{ products: Products }>(GetFacetDataCustomQuery, productSearchParams);
          products = errorProducts;
        // }
        const paginateInfo = await query<{ products }>(getNumberOfProduct, productSearchParamsPaginate);
        const sortedProducts = [];

        for (let i = 0; i < skus.length; i++) {
          const sku = skus[i];
          const product = products.items.find((p) => p.sku === sku);
          if (product) {
            sortedProducts.push(product);
          }
        }
        result.value.data = {
          items: sortedProducts ?? [],
          total: sortProduct.total_count,
          availableSortingOptions: SortingOptions,
          perPageOptions: PerPageOptions,
          itemsPerPage: 24,
        };
      } else {
        const productSearchParams = {
          pageSize,
          search: params.term ? params.term : '',
          filter: createProductAttributeFilterInput(params),
          sort: createProductAttributeSortInput(params.sort || ''),
          currentPage: params.page,
        };
        const { products } = await query<{ products: Products }>(GetFacetDataCustomQuery, productSearchParams)
        result.value.data = {
          items: products.items ?? [],
          total: products?.total_count,
          availableSortingOptions: SortingOptions,
          perPageOptions: PerPageOptions,
          itemsPerPage: 24,
        };
      }
      error.value.search = null;
    } catch (err) {
      error.value.search = err;
    } finally {
      loading.value = false;
    }
  };

  return {
    search,
    result,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './useFacet';
export default useFacet;
