
















import { SfLink } from '@storefront-ui/vue';

export default {
  name: 'HeaderNavigationItem',
  components: {
    SfLink,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: '',
    },
    hasChildren: {
      type: Boolean,
      default: false,
    },
  },
};
