







































































import { defineComponent, useContext, useRoute } from '@nuxtjs/composition-api';
import { SfTopBar, SfLink } from '@storefront-ui/vue';
import { useTopBar } from './useTopBar';

export default defineComponent({
  components: {
    StoreSwitcher: () => import('../StoreSwitcher.vue'),
    SfTopBar,
    SfLink,
  },
  setup() {
    const { app } = useContext();
    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();

    const route = useRoute();
    const linkB2B = route.value.path.substring(0,3) != "/fr"?process.env.PRO_PROMULIAS_URL+ "b2b_en/" :process.env.PRO_PROMULIAS_URL;

    const CartRouter = () => {
      const url = document.location.pathname;
      return url;
    };
    const routeCart = app.localePath('/panier');

    return {
      hasCurrencyToSelect,
      hasStoresToSelect,
      app,
      routeCart,
      CartRouter,
      linkB2B,
    };
  },

  head: {
    link: [{ rel: 'stylesheet', href: '/_nuxt/fonts.css' }],
  },
});
