import { Logger } from '~/helpers/logger';
import { VsfContext } from '~/composables/context';

export const loadBlocksCommand = {
  execute: async (context: VsfContext, params) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const { data } = await context.$magento.api.cmsBlocks(params.identifiers);

    return data.cmsBlocks.items;
  },
};
