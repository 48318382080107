













































































import {
  defineComponent,
  PropType,
  ref,
  useContext,
} from '@nuxtjs/composition-api';
import HeaderNavigationItem from './HeaderNavigationItem.vue';

import { CategoryTree } from '~/modules/GraphQL/types';
import { useUiHelpers } from '~/composables';

export default defineComponent({
  name: 'HeaderNavigation',
  components: {
    HeaderNavigationSubcategories: () =>
      import(
        '~/components/Header/Navigation/HeaderNavigationSubcategories.vue'
      ),
    HeaderNavigationItem,
  },
  props: {
    categoryTree: {
      type: Array as PropType<CategoryTree[]>,
      default: () => [],
    },
  },
  setup() {
    const category = ref(null);
    const { getCatLink } = useUiHelpers();
    const { app } = useContext();
    const currentCategory = ref<CategoryTree>(null);
    const lvl0CatRefs = ref([]);
    const hasFocus = ref(false);
    let lvl0CatFocusIdx = 0;
    let focusedElement = null;

    const setCurrentCategory = (category: CategoryTree | null) => {
      currentCategory.value = category;
    };

    const hasChildren = (category: CategoryTree) => Boolean(category?.children);

    const setFocus = (event) => {
      focusedElement = event.target;
      lvl0CatFocusIdx = Number(event.target.dataset.index);
      hasFocus.value = true;
    };

    const focusRootElementAndHideSubcategories = () => {
      setCurrentCategory(null);
      if (focusedElement !== null) focusedElement.focus();
    };

    const navRight = () => {
      lvl0CatFocusIdx++;
      if (lvl0CatRefs.value[lvl0CatFocusIdx]) {
        lvl0CatRefs.value[lvl0CatFocusIdx].$el.focus();
        focusedElement = lvl0CatRefs.value[lvl0CatFocusIdx].$el;
      } else {
        lvl0CatFocusIdx--;
      }
    };

    const navLeft = () => {
      lvl0CatFocusIdx--;
      if (lvl0CatRefs.value[lvl0CatFocusIdx]) {
        lvl0CatRefs.value[lvl0CatFocusIdx].$el.focus();
        focusedElement = lvl0CatRefs.value[lvl0CatFocusIdx].$el;
      } else {
        lvl0CatFocusIdx++;
      }
    };

    const onMouseEnter = (category: CategoryTree) => {
      currentCategory.value = category;
      focusedElement = null;
      hasFocus.value = false;
      setTimeout(
        function () {
          currentCategory.value = category;
          focusedElement = null;
          hasFocus.value = false;
        }.bind(this),
        300
      );
    };

    return {
      getCatLink,
      setCurrentCategory,
      currentCategory,
      hasChildren,
      setFocus,
      focusRootElementAndHideSubcategories,
      lvl0CatRefs,
      navRight,
      navLeft,
      hasFocus,
      onMouseEnter,
      app,
      category,
    };
  },
});
