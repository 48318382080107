import { Logger } from '~/helpers/logger';
import { VsfContext } from '~/composables/context';
import { ComposableFunctionArgs } from '~/composables/types';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';

export const searchReviewsCommand = {
  execute: async (context: VsfContext, params?: ComposableFunctionArgs<GetProductSearchParams>) => {

    const {
      customQuery,
      ...input
    } = params;

    const { data } = await context.$magento.api.productReview(input as GetProductSearchParams);


    return data?.products?.items ?? [];
  },
};
