import { gql } from 'graphql-request';

/** GraphQL Query that fetches store configuration from the API */
export const StoreConfigQuery = gql`
  query storeConfig {
    storeConfig {
        store_code,
        default_title,
        store_name,
        base_media_url,
        default_display_currency_code,
        locale,
        header_logo_src,
        logo_width,
        logo_height,
        logo_alt
    }
  }
`;

export default StoreConfigQuery;
