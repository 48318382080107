








































import { SfIcon } from '@storefront-ui/vue';
import {
  defineComponent,
  useRouter,
  useContext,
  computed,
} from '@nuxtjs/composition-api';
import { useConfig } from '~/composables';

export default defineComponent({
  components: {
    SfIcon,
  },
  setup() {
    const { app } = useContext();
    const router = useRouter();
    const returnToCart = async () => {
      await router.push(`${app.localePath('/panier')}`);
    };

    const { config } = useConfig();
    const logoSrc = computed(() => {
      const baseMediaUrl = config.value.base_media_url;
      const logo = config.value.header_logo_src;

      return baseMediaUrl && logo ? `${baseMediaUrl}logo/${logo}` : '';
    });

    const CartRouter = () => {
      const url = document.location.pathname;
      return url;
    };

    return {
      returnToCart,
      logoSrc,
    };
  },
});
