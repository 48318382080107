import { Logger } from '~/helpers/logger';
import { BillingCartAddress, Maybe, SetBillingAddressOnCartInput } from '~/modules/GraphQL/types';

export const saveBillingAddressCommand = {
  execute: async (context, cartId, billingDetails): Promise<Maybe<BillingCartAddress>> => {
    const {
      apartment,
      neighborhood,
      extra,
      sameAsShipping,
      customerAddressId,
      ...address
    } = billingDetails;
    const billingData = sameAsShipping
      ?({ customer_address_id: parseInt(customerAddressId),
          same_as_shipping: sameAsShipping,
      })
      : ({
        address: {
          ...address,
          street: [address.street, apartment, neighborhood, extra].filter(Boolean) [0],
          region: address.region.region?address.region.region:address.region
        },
        same_as_shipping: sameAsShipping,
      });
    if(!customerAddressId){
      const keysToDelete: string[] = ["id","vat_id","suffix", "prefix", "extension_attributes", "default_billing","default_shipping"];

      keysToDelete.forEach(key => {
        delete billingData.address[key];
      });
    }
    const setBillingAddressOnCartInput: SetBillingAddressOnCartInput = {
      cart_id: cartId,
      billing_address: billingData,
    };

    const { data } = await context.$vsf.$magento.api.setBillingAddressOnCart(
      setBillingAddressOnCartInput,
    );

    return data?.setBillingAddressOnCart?.cart?.billing_address ?? null;
  },
};
